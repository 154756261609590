// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getAuth, signInAnonymously } from "firebase/auth"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZKWEFLCe_ZQpacfOcvh1Y4cuCJOMf6HQ",
  authDomain: "alpha-cyborg.firebaseapp.com",
  databaseURL: "https://alpha-cyborg-default-rtdb.firebaseio.com",
  projectId: "alpha-cyborg",
  storageBucket: "alpha-cyborg.appspot.com",
  messagingSenderId: "750735467115",
  appId: "1:750735467115:web:e09840545310e70734c72e",
  measurementId: "G-PZDSZDYY7S",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const functions = getFunctions(app)
if (window.location.href.includes("localhost")) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}
export const auth = getAuth(app)
signInAnonymously(auth)
