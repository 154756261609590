import React from "react"
import logo from "./keyboard.png"
import "./App.css"
import { Sounds } from "./Sounds"
import { Charts } from "./Charts"
import { Intro } from "./Intro"
import { PageLocation } from "./Nav"
import { Gate } from "./Gate"
import { Cable } from "./Cable"

function App() {
  const [page, setPage] = React.useState(PageLocation.Home)

  return (
    <div className="App">
      {page === PageLocation.Home && <Intro setPage={setPage} />}
      {page === PageLocation.Sounds && <Sounds setPage={setPage} />}
      {page === PageLocation.ChartsGate && <Gate setPage={setPage} stage="s1" successNav={PageLocation.Charts} />}
      {page === PageLocation.Charts && <Charts setPage={setPage} />}
      {page === PageLocation.CableGate && <Gate setPage={setPage} stage="s2" successNav={PageLocation.Cable} />}
      {page === PageLocation.Cable && <Cable setPage={setPage} />}
      {page === PageLocation.PhysicalGate && <Gate setPage={setPage} stage="s3" successNav={PageLocation.Home} />}
      {page === PageLocation.Final && <Gate setPage={setPage} stage="s4" successNav={PageLocation.Home} />}
    </div>
  )
}

export default App
