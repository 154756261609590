import React from "react"
import { Stage, Layer, Rect, Text } from "react-konva"
import { httpsCallable } from "firebase/functions"
import { playNote } from "./playnote"
import { functions, auth } from "./firebase"
import { PageLocation, SetPageProps } from "./Nav"

const onNote = httpsCallable(functions, "onNote")

const WIDTH = 40

const notes = "CDEFGAB".split("")
const OCTAVE = 2

async function doPlay(team: string, note: string): Promise<any> {
  if (team === "") return

  playNote(note)
  const user = auth.currentUser?.uid ?? "unknown"

  const response = await onNote({ note, team, user })
  console.log(response.data)
  return response.data
}

const INITIAL_MSG = `Ok, I've connected through to the subconscious and the access code seems to be connected to a memory, but I can't
make it out. Maybe you can help me stimulate the proper memories so we can uncover the access code to the firewall.
The memory seems to be connected to a name: Walter Murphy. Any idea who that is and what it might mean?`

export const Sounds = ({ setPage }: SetPageProps) => {
  const [editTeam, setEditTeam] = React.useState("")
  const [team, setTeam] = React.useState("")
  const [message, setMessage] = React.useState(INITIAL_MSG)

  const top = 50
  const res: JSX.Element[] = []
  for (let i = 0; i < 14; i++) {
    const left = 50 + i * WIDTH
    const note = notes[i % notes.length] + Math.floor(OCTAVE + i / notes.length)

    const onClick = () =>
      doPlay(team, note).then((resp: any) => {
        if (resp.result?.message) setMessage(resp.result?.message)
      })

    res.push(
      <Rect
        x={left}
        y={top}
        width={WIDTH}
        height={170}
        fill="white"
        zIndex={0}
        stroke="black"
        cornerRadius={[0, 0, 10, 10]}
        onClick={onClick}
        onTap={onClick}
      />
    )
  }
  for (let i = 0; i < 14; i++) {
    const left = 50 + i * WIDTH
    if ([2, 6, 9, 13].includes(i)) continue

    let blackWidth = WIDTH * 0.7
    const note = notes[i % notes.length] + "#" + Math.floor(OCTAVE + i / notes.length)
    const onClick = () =>
      doPlay(team, note).then((resp: any) => {
        if (resp.result?.message) setMessage(resp.result?.message)
      })

    res.push(
      <Rect
        x={left + WIDTH - blackWidth / 2}
        y={top}
        width={blackWidth}
        height={120}
        fill="black"
        zIndex={100}
        cornerRadius={[0, 0, 7, 7]}
        onClick={onClick}
        onTap={onClick}
      />
    )
  }
  if (team === "") {
    return (
      <>
        <button onClick={() => setPage(PageLocation.Home)}>Home</button>
        <p />
        Choose a team: <input value={editTeam} onChange={(e) => setEditTeam(e.target.value)} />
        &nbsp;
        <button onClick={() => setTeam(editTeam)}>Ok</button>
      </>
    )
  }

  return (
    <>
      <button onClick={() => setPage(PageLocation.Home)}>Home</button>
      <Stage width={800} height={300}>
        <Layer>{res}</Layer>
      </Stage>
      {message}
      <p />
    </>
  )
}
