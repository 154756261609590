import { SetPageProps, PageLocation } from "./Nav"

export function Cable({ setPage }: SetPageProps) {
  return (
    <>
      <button onClick={() => setPage(PageLocation.Home)}>Home</button>
      <p>
        I've got this memory, I think it might be implanted, but if we unlock it we can get through the next back door
      </p>
      <ol>
        <li>
          Five investors enter the company cafeteria; they’re talking about working together on some sort of client
          report. I don’t know their names, so I’ll just call them investor 1 through 5.{" "}
        </li>
        <li>
          Each one is wearing a security badge, but instead of their name it just has a number. Oddly, the badges are
          numbered with the first five fibbonacci numbers, in investor order.
        </li>
        <li>
          Everybody grabs an iPad from the table, but investor 3 drops theirs and cracks the screen. She picks it up and
          uses it anyway. They sit in a circle, in investor order (2 is to the right of 1).
        </li>
        <li>Everyone with an odd badge number creates a new chart on the iPad, the remainder start a new table.</li>
        <li>
          The first step in every report is adding titles. After some debate, they decide to start with something famous
          - each person working on a chart uses the nth line from the body of the poem at the base of the Statue of
          Liberty, where n is their badge number.{" "}
        </li>
        <li>Anyone working on a table chooses the nth line of of the poem, but from the end as their starting text.</li>
        <li>"know-how" is two words.</li>
        <li>
          Sitting on the stove next to them is a pot of water, with steam beginning to waft out of it. A frog jumps out
          and hops over to land on the cracked iPad.{" "}
        </li>
        <li>
          Everyone stands up and moves to the first open seat on their left. Except the person with the frog, of course.
        </li>
        <li>
          The frog hops as many people to the right as there are letters in the first word of the title on its iPad.{" "}
        </li>
        <li>
          If the frog is on a chart, it croaks the fourth word of the title. If it’s on a table, it croaks the third
          word.
        </li>
        <li>Repeat the prior 2 steps. The frog has spoken (though it speaks in uppercase without spaces).</li>
      </ol>
    </>
  )
}
