import React, { useMemo } from "react"
import { PageLocation, SetPageProps } from "./Nav"

export function Intro({ setPage }: SetPageProps) {
  return (
    <div className="intro">
      <p>You found me? us? it?</p>
      <p>Anyway - you are here. I'm here. Wherever this is. My memory is pretty hazy.</p>
      <p>Someone burned a few rules into my hippocampus. Make sure you remember them too. They might matter.</p>
      <ol>
        <li>Hail Archon.</li>
        <li>Everything goes down on campus</li>
        <li>Fragile things and fragile memories should NOT be broken</li>
        <li>Networked minds produce results, but tell not</li>
        <li>View source is for wusses</li>
      </ol>
      <p>
        If you help me, I think I can help you uncover something about Chuck. I need you to network with me. I've found
        some backdoors, but I don't know how to get through them. Can you help me?
      </p>
      <button onClick={() => setPage(PageLocation.Sounds)}>Backdoor 1</button>&nbsp;
      <button onClick={() => setPage(PageLocation.ChartsGate)}>Backdoor 2</button>&nbsp;
      <button onClick={() => setPage(PageLocation.CableGate)}>Backdoor 3</button>&nbsp;
      <button onClick={() => setPage(PageLocation.PhysicalGate)}>Backdoor 4</button>&nbsp;
      <button onClick={() => setPage(PageLocation.Final)}>Backdoor 5</button>
    </div>
  )
}
