import React, { useMemo } from "react"
import { Stage, Layer, Rect, Text, Line, Arrow, Image } from "react-konva"
import { httpsCallable } from "firebase/functions"
import { functions } from "./firebase"
import useImage from "use-image"
import { PageLocation, SetPageProps } from "./Nav"

const imageUrl = "/cyborg-part.png"
// const imageUrl = "/cyborg-image.png"

const screenWidth = 1000
const screenHeight = 600
const left = 50
const top = 50
const startDate = 1970
const endDate = 2025
const dateIncr = 5
const startPct = -75
const endPct = 75
const pctIncr = 25
const maxArrowCount = 7
const counts = [3, 4, 4, 6]

const onChart = httpsCallable(functions, "onChart")

async function doMove(
  curHeights: number[],
  game: string,
  stack: number,
  index: number
): Promise<{ stack?: number; index?: number; endGame?: string; passcode?: string }> {
  console.log(`Move ${stack} ${index}`)
  const response: any = (await onChart({ game, stack, index })).data
  console.log(response)
  if (response.fail) {
    console.log(`Failed ${response.error}`)
  }
  return response.result
}

// mulberry32 - an actual high quality 32-bit generator
function LCG(s: number) {
  return function () {
    s = (s * 48271) % 2147483647
    return s / 2147483647
  }
}
const rand = LCG(516)

// Computed
const width = screenWidth - 2 * left
const height = screenHeight - 2 * top
const steps = 100
const arrowHeight = height / 2 / maxArrowCount
const botX = left + width - 200

const lineX1 = botX + 100
const lineY1 = top + 80

const els: JSX.Element[] = []
for (let year = startDate; year <= endDate; year += dateIncr) {
  const x = left + ((year - startDate) / (endDate - startDate)) * (width - 50)
  els.push(<Text x={x} y={top + height + 5} text={year.toString()} key={`yr-${year}`} fill="white" />)
}

for (let pct = startPct; pct <= endPct; pct += pctIncr) {
  let y = top + ((pct - endPct) / (startPct - endPct)) * (height - 70) + 15
  els.push(
    <Text
      x={left + width + 5}
      y={y}
      text={`${(pct / 10).toFixed(1)}%`}
      verticalAlign="middle"
      key={`pctlbl-${pct}`}
      fill="white"
    />
  )
  y += 5
  if (pct === 0) {
    els.push(<Line points={[left, y, left + width, y]} stroke="black" strokeWidth={1} key={`axis-${pct}`} />)
  }
}

const points: number[] = []
let baseY = 0
for (let i = 0; i < steps; i++) {
  const pct = baseY * (endPct - startPct) + (endPct + startPct) / 2

  let y = top + ((pct - endPct) / (startPct - endPct)) * (height - 70) + 15
  const x = left + (i / steps) * (width - 50)
  points.push(x)
  points.push(y)

  let sub = 0.5
  if (baseY > 0.5) sub = 0.75
  if (baseY < -0.5) sub = 0.25

  baseY = baseY + (rand() - sub) / 5
  points.push(x + 3)
  points.push(y)
}

els.push(<Line points={points} stroke="red" strokeWidth={2} key={`points`} />)
let zeroY = top + ((0 - endPct) / (startPct - endPct)) * (height - 70) + 15
let topY = top + ((startPct - endPct) / (startPct - endPct)) * (height - 70) + 15

const arrows: { [key: string]: number[] } = {}
const deadArrows: { [key: string]: number[] } = {}

for (let i = 0; i < counts.length; i++) {
  let count = counts[i]
  if (count == 0) continue
  const index = ((i / (counts.length + 1)) * points.length + 30) & 0xfffc
  let x = points[index]
  let y = points[index + 1]
  const yDir = y > zeroY ? -1 : 1

  const arrowPts = []
  let xDir = 1
  for (let j = 0; j <= count; j++) {
    arrowPts.push(x)
    arrowPts.push(y)
    let x2 = x + xDir * (10 + rand() * 30)
    let y2 = y + yDir * arrowHeight
    const id = `${i}-${j}`
    arrows[id] = [x, y, x2, y2]
    const arrowLen = Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y))
    const deadShift = 5 + rand() * 10
    deadArrows[id] = [x + deadShift, topY, x2 + deadShift + arrowLen, topY]
    x = x2
    y = y2
    xDir = -xDir
  }
}

export function Charts({ setPage }: SetPageProps) {
  const [heights, setHeights] = React.useState(counts)
  const [game, setGame] = React.useState(crypto.randomUUID())
  const [busy, setBusy] = React.useState(false)

  const arrowRefs = React.useRef<{ [key: string]: any }>({})
  const [image] = useImage(imageUrl)
  const lineRef = React.useRef<any>(null)
  const botTextRef = React.useRef<any>(null)

  const myEls = [...els]

  const dropArrows = (stack: number, index: number, onFinish?: () => void) => {
    for (let i = index; i < heights[stack]; i++) {
      const id = `${stack}-${i}`
      const arrow = arrowRefs.current[id]
      arrow.to({
        opacity: 0,
        strokeWidth: 20,
        duration: 0.75,
        onFinish,
      })
    }
  }

  const fadeLine = (stack: number, index: number, message: string, onFinish?: () => void) => {
    if (!lineRef.current || !botTextRef.current) {
      onFinish?.()
      return
    }
    const id = `${stack}-${index}`
    const pts = arrows[id]
    const center = [(pts[0] + pts[2]) / 2, (pts[1] + pts[3]) / 2]
    lineRef.current.points([lineX1, lineY1, center[0], center[1]])

    botTextRef.current.text(message)
    botTextRef.current.to({
      opacity: 1,
      duration: 0.25,
      onFinish: () => {
        lineRef.current.to({
          opacity: 1,
          duration: 0.25,
          onFinish: () => {
            lineRef.current.to({
              opacity: 0,
              duration: 0.25,
              onFinish,
            })
          },
        })
        botTextRef.current.to({
          opacity: 0,
          duration: 1,
        })
      },
    })
  }

  const onClick = (userStack: number, userIndex: number) => {
    if (busy) return
    setBusy(true)
    doMove(heights, game, userStack, userIndex)
      .then((move) => {
        if (move.endGame) {
          setHeights(heights.map(() => 0))
          if (move.passcode) {
            botTextRef.current.text(`You won. Passcode is ${move.passcode}`)
          } else {
            dropArrows(userStack, userIndex)
            botTextRef.current.text(`I won.`)
          }
          botTextRef.current.opacity(1)
          setBusy(false)
          return
        }
        const { stack: compStack, index: compIndex } = move
        if (compStack === undefined || compIndex === undefined) return

        console.log(`User ${userStack} ${userIndex}, computer ${compStack} ${compIndex}`)
        dropArrows(userStack, userIndex, () => {
          fadeLine(compStack, compIndex, "My turn", () => {
            dropArrows(compStack, compIndex, () => {
              const newHeights = [...heights]
              newHeights[compStack] = compIndex
              newHeights[userStack] = userIndex
              setHeights(newHeights)
              setBusy(false)
            })
          })
        })
      })
      .catch((e) => {
        console.log(`Failed ${e}`)
      })
  }

  const changeCursor = (e: any, cursor: string) => {
    e.target.getStage().container().style.cursor = cursor
  }

  for (let stack = 0; stack < heights.length; stack++) {
    for (let index = 0; index < counts[stack]; index++) {
      const id = `${stack}-${index}`
      const handlers = {
        onClick: () => onClick(stack, index),
        onTap: () => onClick(stack, index),
        onMouseEnter: (e: any) => changeCursor(e, "pointer"),
        onMouseLeave: (e: any) => changeCursor(e, "default"),
      }

      if (index < heights[stack]) {
        const arrow = (
          <Arrow
            ref={(node) => (arrowRefs.current[id] = node)}
            points={arrows[id]}
            stroke="black"
            fill="black"
            strokeWidth={2}
            key={id}
            hitStrokeWidth={10}
            {...handlers}
          />
        )
        myEls.push(arrow)
      } else {
        const arrow = (
          <Arrow
            ref={(node) => (arrowRefs.current[id] = node)}
            points={arrows[id]}
            opacity={0}
            stroke="black"
            fill="black"
            strokeWidth={2}
            key={id}
          />
        )
        myEls.push(arrow)
      }
    }
  }

  return (
    <>
      <button onClick={() => setPage(PageLocation.Home)}>Home</button>
      <Stage width={screenWidth} height={screenHeight}>
        <Layer>
          <Rect x={left} y={top} width={width} height={height} fill="white" stroke="black" />
          {myEls}
          <Image image={image} x={botX} y={top} width={200} height={500} />
          <Line
            ref={lineRef}
            points={[lineX1, lineY1, botX - 300, top + 200]}
            fill="yellow"
            stroke="yellow"
            strokeWidth={3}
            opacity={0}
          />
          <Text
            ref={botTextRef}
            x={botX - 550}
            y={top + 50}
            width={600}
            text=""
            fontSize={30}
            fill="blue"
            opacity={1}
            align="right"
          />
        </Layer>
      </Stage>
      Woah! It looks like we've encountered some more emotional baggage - no idea how this thing got in here. We've got
      to figure out how to get past it.
    </>
  )
}
