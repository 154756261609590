import * as Tone from "tone"

// const midiNote = 67
// const bpm = 120

// const synth = new Tone.Synth().toDestination()
const synth = new Tone.PolySynth(Tone.Synth).toDestination()

//play a middle 'C' for the duration of an 8th note
export const playNote = (note) => {
  synth.triggerAttackRelease(note, "16n")
}
