import React, { useMemo } from "react"
import { Stage, Layer, Rect, Text, Line, Arrow, Image } from "react-konva"
import { httpsCallable } from "firebase/functions"
import { functions } from "./firebase"
import useImage from "use-image"
import { PageLocation, SetPageProps } from "./Nav"
import denied from "./denied.png"

export interface GateProps {
  setPage: (page: PageLocation) => void
  stage: string
  successNav: PageLocation
}
const onPasscode = httpsCallable(functions, "onPasscode")

interface ServerResponse {
  paragraphs?: string[]
  invalid?: boolean
  timeLock?: boolean
}

export function Gate({ setPage, stage, successNav }: GateProps) {
  const [passcode, setPasscode] = React.useState("")
  const [response, setResponse] = React.useState<ServerResponse>({})
  if (response.timeLock) {
    return (
      <>
        <button onClick={() => setPage(PageLocation.Home)}>Home</button>
        <p />
        <img src={denied} />
      </>
    )
  }

  if (response.paragraphs) {
    return (
      <>
        <button onClick={() => setPage(PageLocation.Home)}>Home</button>
        <p />
        {response.paragraphs.map((p, i) => (
          <p key={i}>{p}</p>
        ))}
        <button onClick={() => setPage(successNav)}>Continue...</button>
      </>
    )
  }

  const submitCode = () => {
    onPasscode({ stage, passcode }).then((resp) => {
      const data = resp.data as ServerResponse
      setResponse(data)
    })
  }

  return (
    <>
      <button onClick={() => setPage(PageLocation.Home)}>Home</button>
      <p />
      {response.invalid && <p>Invalid access code</p>}
      <p>
        Enter Access Code: <input value={passcode} onChange={(e) => setPasscode(e.target.value)} />
        &nbsp;<button onClick={() => submitCode()}>Submit</button>
      </p>
    </>
  )
}
