export enum PageLocation {
  Home,
  Sounds,
  ChartsGate,
  Charts,
  CableGate,
  Cable,
  PhysicalGate,
  Final,
}

export type SetPage = (page: PageLocation) => void
export interface SetPageProps {
  setPage: SetPage
}
